import { useCallback } from 'react'
import { useMessage } from './useMessage'
import { usePlayerData } from './usePlayerData'
import axiosClient, { getErrorMessage } from '../services/axiosInstance'
import { config } from '../config'
import { AxiosError } from 'axios'
import { MapInfoType } from '../types/api/mapInfoType'

export const useMapInfo = () => {
  const { showMessage } = useMessage()
  const { setMapInfoData } = usePlayerData()

  const fetchMapInfo = useCallback(async () => {
    try {
      const res = await axiosClient.get<MapInfoType>(`${config.MAP_INFO_API}`)
      if (res?.data?.statusCode !== 200) {
        console.log('MapInfoの取得に失敗しました', res)
        showMessage({ text: getErrorMessage(res?.data?.statusCode), status: 'error' })
        return
      }
      const oldData = res.data.data
      const sortedData = oldData.slice().sort((a, b) => {
        if (a.displayNumber === null && b.displayNumber === null) {
          return oldData.indexOf(a) - oldData.indexOf(b)
        }
        if (a.displayNumber === null) return 1
        if (b.displayNumber === null) return -1
        if (a.displayNumber === b.displayNumber) {
          return oldData.indexOf(a) - oldData.indexOf(b)
        }
        return a.displayNumber - b.displayNumber
      })
      setMapInfoData(sortedData)
    } catch (error) {
      const axiosError = error as AxiosError
      showMessage({ text: getErrorMessage(axiosError?.response?.status), status: 'error' })
    }
  }, [])

  return { fetchMapInfo }
}
