export const config = {
  PLAYER_AUTH_API: `${process.env.REACT_APP_API_URL}/player/auth`,
  PLAYER_REFRESH_TOKEN_API: `${process.env.REACT_APP_API_URL}/player/auth/refreshToken`,
  PROJECT_SIGNED_COOKIES_API: `${process.env.REACT_APP_API_URL}/player/project/signedCookies`,
  PROJECT_INFO_API: `${process.env.REACT_APP_API_URL}/player/project`,
  LIST_SPACE_API: `${process.env.REACT_APP_API_URL}/player/project/space`,
  SCENE_INFO_API: `${process.env.REACT_APP_API_URL}/player/scene/`,
  MAP_INFO_API: `${process.env.REACT_APP_API_URL}/player/project/mapInfos`,
  GRAPHQL_ENDPOINT: `${process.env.REACT_APP_API_URL}/cms`,
  REDIRECT_URL: 'https://hello.whereness.io/',
  FADE_IN_XS: { TIME: 0.12, EASE: 'linear' },
  FADE_IN_SM: { TIME: 0.12, EASE: [0.3, 0.3, 0.3, 1], EASE_PLAIN: '0.3, 0.3, 0.3, 1' },
  FADE_IN_MD: { TIME: 0.2, EASE: [0.3, 0.3, 0.3, 1], EASE_PLAIN: '0.3, 0.3, 0.3, 1' },
  FADE_IN_LG: { TIME: 0.24, EASE: [0.3, 0.3, 0.3, 1], EASE_PLAIN: '0.3, 0.3, 0.3, 1' },
  FADE_IN_XL: { TIME: 0.24, EASE: [0.5, 0.5, 0.5, 1] },
  FADE_IN_2XL: { TIME: 0.32, EASE: [0.5, 0.5, 0.5, 1], EASE_PLAIN: '0.5, 0.5, 0.5, 1' },
  FADE_OUT_XS: { TIME: 0.2, EASE: 'linear', EASE_PLAIN: 'linear' },
  FADE_OUT_SM: { TIME: 0.24, EASE: 'linear', EASE_PLAIN: 'linear' },
  FADE_OUT_MD: { TIME: 0.24, EASE: [0.3, 0.3, 0.3, 1], EASE_PLAIN: '0.3, 0.3, 0.3, 1' },
  FADE_OUT_XL: { TIME: 1, EASE: [0.5, 0, 0.6, 0.5], EASE_PLAIN: '0.5, 0, 0.6, 0.5' },
  AUTOPLAY_IMAGE_TIME: 15000,
  TOOLTIP: { DELAY: 400, OFFSET: 4 },
  NAVIGATION_WIDTH: 240,
  VIDEO_CONTROL_WIDTH: 246,
  BGM_CONTROL_WIDTH: 103,
  FULLSCREEN_CONTROL_WIDTH: 56,
  CONTROL_SPACE: 16,
  ENABLE_SCENE_INFO: false,
  TAG_ZOOM_SCALE: { BASE: 3, SM: 2 },
  MAP_ZOOM_SCALE: { BASE: 3, SM: 2 },
  ALL_FEATURES: ['2d-map'] as const,
}
