import { lazy, memo, Suspense, useEffect, useState, VFC } from 'react'
import { useParams } from 'react-router-dom'
import { usePage } from '../../hooks/usePage'
import { useProjectInfo } from '../../hooks/useProjectInfo'
import { Landing } from './Landing'
import { Loading } from './Loading'
import { useSignedCookies } from '../../hooks/useSignedCookies'
import { useAuth } from '../../hooks/useAuth'
import { useListSpace } from '../../hooks/useListSpace'
import { useActiveNumber } from '../../hooks/useActiveNumber'
import { useFirstSceneLoading } from '../../hooks/providers/useFirstSceneLoading'
import { AnimatePresence } from 'framer-motion'
import { useCookie } from '../../hooks/useCookie'
import { usePlayerData } from '../../hooks/usePlayerData'
import { SceneLoading } from '../organisms/SceneLoading'
import { shouldHandleShortcut } from '../../services/shortcut'
import { viewType } from '../../types/playerDataType'
import { VIEW_TYPE } from '../../config/playerData'
import { useMapInfo } from '../../hooks/useMapInfo'

const Space = lazy(() => import('./Space'))

type PlayerType = {
  type: viewType
}

export const Player: VFC<PlayerType> = memo((props) => {
  const { type } = props
  const { page, setPage } = usePage()
  const { projectPublishId } = useParams() // Project ID
  const [firstLoading, setFirstLoading] = useState(true)
  const [isSpace, setIsSpace] = useState(false)
  const [isReady, setIsReady] = useState({ wait: false, loading: false }) // // 初回ローディングのチラつき防止の為、最低1秒待つ
  const { setProjectPublishId, setViewType, viewType, enabledFeatures } = usePlayerData()
  const { setActiveNumber } = useActiveNumber()
  const { isFirstSceneLoading, setIsFirstSceneLoading } = useFirstSceneLoading()
  const { fetchAuth } = useAuth()
  const { fetchSignedCookies } = useSignedCookies()
  const { fetchProjectInfo } = useProjectInfo()
  const { fetchListSpace } = useListSpace()
  const { checkCookie } = useCookie()
  const { fetchMapInfo } = useMapInfo()

  useEffect(() => {
    setProjectPublishId(projectPublishId)
    sessionStorage.setItem('projectPublishId', `{"projectPublishId":"${projectPublishId}"}`)

    setViewType(type)

    // preview時cms_access_tokenのcookieが存在しない場合は、Studioにリダイレクト
    if (type === VIEW_TYPE.PREVIEW && !checkCookie('cms_access_token')) {
      window.location.replace(process.env.REACT_APP_STUDIO_URL)
    }

    const getFirstApi = async () => {
      setFirstLoading(true)
      setIsSpace(false)
      setIsFirstSceneLoading(true)

      const resAuth = await fetchAuth({ type: type, projectPublishId: projectPublishId })
      if (resAuth?.data.statusCode !== 200) {
        return
      }

      const resSignedCookies = await fetchSignedCookies()
      if (resSignedCookies?.data.statusCode !== 200) {
        return
      }

      const resProjectInfo = await fetchProjectInfo({ type: type })
      if (resProjectInfo?.data.statusCode !== 200) {
        return
      }

      const resListSpace = await fetchListSpace()
      if (resListSpace?.data.statusCode !== 200) {
        return
      }

      setActiveNumber({ spaceNumber: 0, sceneNumber: 0 }) //現在の番号 に格納
      setPage('landing')
      setIsSpace(true) // Spaceコンポーネント有効
    }
    getFirstApi()
  }, [projectPublishId, type])

  useEffect(() => {
    if (!enabledFeatures || !enabledFeatures['2d-map']) {
      return
    }
    fetchMapInfo()
  }, [enabledFeatures])

  // 初回Sceneメディアの読み込みが完了
  useEffect(() => {
    setIsReady((prevState) => ({ ...prevState, loading: true })) // ローディング完了
  }, [isFirstSceneLoading])

  useEffect(() => {
    // 初回ローディングのチラつき防止の為、最低数秒待つ
    setTimeout(() => {
      setIsReady((prevState) => ({ ...prevState, wait: true }))
    }, 800)

    // ショートカット設定
    window.addEventListener('keydown', (event) => {
      // フルスクリーン
      if (shouldHandleShortcut(event, 'f')) {
        if (!document.fullscreenElement) {
          document.documentElement.requestFullscreen()
        }
      }
      // フルスクリーン解除
      if (event.key === 'Escape') {
        if (document.fullscreenElement) {
          document.exitFullscreen()
        }
      }
    })
  }, [])

  useEffect(() => {
    if (isReady.wait && isReady.loading) {
      setFirstLoading(false)
    }
  }, [isReady])

  return (
    <>
      <AnimatePresence exitBeforeEnter>{firstLoading && <Loading degree={50} />}</AnimatePresence>
      <Suspense fallback={<SceneLoading isOpenNavigation={true} />}>{isSpace && <Space />}</Suspense>
      <AnimatePresence exitBeforeEnter>{page === 'landing' && <Landing />}</AnimatePresence>
    </>
  )
})
