import { theme as origTheme, extendTheme, keyframes } from '@chakra-ui/react'
import { config } from '../../config'

const theme = extendTheme({
  breakpoints: {
    sm: '520px',
    md: '960px',
    lg: '1200px',
    xl: '1440px',
    '2xl': '1600px',
  },
  styles: {
    global: {
      body: {
        backgroundColor: 'gray.900',
        color: 'rgba(255, 255, 255, 0.97)',
      },
      '.chakra-modal__content-container::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
  fonts: {
    heading: `Montserrat, 'Noto Sans JP', sans-serif`,
    body: `Lato, 'Noto Sans JP', sans-serif`,
  },
  colors: {
    body: 'rgba(255, 255, 255, 0.97)',
    blue: {
      900: '#000E47',
      800: '#001D73',
      700: '#002F9E',
      600: '#0046D7',
      500: '#2067E3',
      450: '#397DE3',
      400: '#5694F0',
      300: '#8EC2F5',
      200: '#B5D9F7',
      100: '#B5D9F7',
      50: '#E8F3FA',
    },
    red: {
      900: '#3D0103',
      800: '#660101',
      700: '#9A0902',
      600: '#C71B08',
      500: '#E0321B',
      400: '#F26C57',
      300: '#FAA49B',
      200: '#FAC4BF',
      100: '#FADFDC',
      50: '#FAF0F1',
    },
    gray: {
      900: '#1A1A1A',
      800: '#292929',
      700: '#3D3D3D',
      600: '#525252',
      500: '#707070',
      400: '#999999',
      300: '#C2C2C2',
      200: '#E0E0E0',
      100: '#F0F0F0',
      50: '#F7F7F7',
    },
    black: {
      900: 'rgba(0, 0, 0, 0.9)',
      800: 'rgba(0, 0, 0, 0.84)',
      700: 'rgba(0, 0, 0, 0.76)',
      600: 'rgba(0, 0, 0, 0.68)',
      500: 'rgba(0, 0, 0, 0.56)',
      400: 'rgba(0, 0, 0, 0.4)',
      300: 'rgba(0, 0, 0, 0.24)',
      200: 'rgba(0, 0, 0, 0.12)',
      100: 'rgba(0, 0, 0, 0.06)',
      50: 'rgba(0, 0, 0, 0.03)',
    },
    white: {
      1000: 'rgba(255, 255, 255, 1)',
      900: 'rgba(255, 255, 255, 0.97)',
      800: 'rgba(255, 255, 255, 0.94)',
      700: 'rgba(255, 255, 255, 0.88)',
      600: 'rgba(255, 255, 255, 0.76)',
      500: 'rgba(255, 255, 255, 0.6)',
      400: 'rgba(255, 255, 255, 0.44)',
      350: 'rgba(255, 255, 255, 0.40)',
      300: 'rgba(255, 255, 255, 0.32)',
      200: 'rgba(255, 255, 255, 0.24)',
      100: 'rgba(255, 255, 255, 0.16)',
      50: 'rgba(255, 255, 255, 0.1)',
    },
  },
  zIndices: {
    loading: 1700,
    landingImage: 1600,
    landingInner: 1500,
    landing: 1400,
    tagModalFullBase: 1300,
    publishSm: 1200,
    mapModal: 1100,
    navigationClose: 1040,
    navigationTitle: 1030,
    navigationTab: 1020,
    navigationInner: 1010,
    navigationBg: 1000,
    navigation: 1000,
    scenename: 900,
    scenenameIcon: 800,
    publish: 700,
    tagModalBase: 610,
    tagModalBg: 600,
    sceneControl: 500,
    sceneDescription: 400,
    sceneLoading: 300,
    spaceEntrance: 200,
    tagModalSm: 100,
  },

  components: {
    Heading: {
      sizes: {
        '2xl': {
          fontSize: '36px',
          lineHeight: '44px',
          letterSpacing: '-0.01em',
        },
        xl: {
          fontSize: '28px',
          lineHeight: '36px',
          letterSpacing: '-0.01em',
        },
        lg: {
          fontSize: '20px',
          lineHeight: '28px',
          letterSpacing: '-0.01em',
        },
        md: {
          fontSize: '16px',
          lineHeight: '24px',
          letterSpacing: '-0.01em',
        },
      },
    },
    Button: {
      baseStyle: {
        transition: `all ${config.FADE_IN_SM.TIME}s cubic-bezier(${config.FADE_IN_SM.EASE_PLAIN})`,
      },
      sizes: {
        sm: {
          fontSize: { base: '14px', md: '16px' },
          lineHeight: { base: '20px', md: '24px' },
        },
        md: {
          w: 'auto',
          h: '36px',
          fontSize: '14px',
          lineHeight: '20px',
          fontWeight: '600',
          padding: '8px 16px',
          borderRadius: '16px',
          _before: {
            content: `""`,
            display: 'block',
            w: '100%',
            h: '48px',
            position: 'absolute',
            top: '-6px',
            left: '0',
          },
        },
        lg: {
          w: '100%',
          h: 'auto',
          fontWeight: '600',
          fontSize: { base: '16px', lg: '20px' },
          padding: { base: '12px 16px 12px 12px', sm: '15px 16px 15px 12px', lg: '16px 16px 16px 12px' },
          borderRadius: { base: '16px', md: '24px' },
          letterSpacing: '-0.01em',
          lineHeight: { base: '24px', lg: '28px' },
        },
        sceneSelect: {
          fontWeight: '600',
          fontSize: '16px',
          lineHeight: '24px',
          letterSpacing: '-0.01em',
          padding: '16px 16px 12px 16px',
        },
        iconXl: {
          w: { base: '48px', sm: '56px' },
          h: { base: '48px', sm: '56px' },
          borderRadius: '16px',
        },
        iconLg: {
          w: '48px',
          h: '48px',
          borderRadius: '16px',
        },
        iconMd: {
          w: '40px',
          h: '40px',
          position: 'relative',
          borderRadius: '8px',
          _before: {
            content: `""`,
            display: 'block',
            w: '48px',
            h: '48px',
            position: 'absolute',
            top: '-4px',
            left: '-4px',
          },
        },
        iconMdRadiusMd: {
          w: '40px',
          h: '40px',
          position: 'relative',
          borderRadius: '12px',
          _before: {
            content: `""`,
            display: 'block',
            w: '48px',
            h: '48px',
            position: 'absolute',
            top: '-4px',
            left: '-4px',
          },
        },
        iconMdRadiusLg: {
          w: '40px',
          h: '40px',
          position: 'relative',
          borderRadius: '16px',
          _before: {
            content: `""`,
            display: 'block',
            w: '48px',
            h: '48px',
            position: 'absolute',
            top: '-4px',
            left: '-4px',
          },
        },
      },
      variants: {
        link: {
          color: 'blue.200',
          _hover: { color: 'blue.200', textDecoration: 'none' },
          _active: { color: '.200', textDecoration: 'none' },
          _focus: { color: 'blue.200', textDecoration: 'none', boxShadow: 'none' },
          _disabled: { color: 'white.300', textDecoration: 'none', cursor: 'default' },
          padding: { base: '10px 0', sm: '8px 0' },
        },
        solid: {
          fontFamily: `Montserrat, 'Noto Sans JP', sans-serif`,
          bg: 'blue.500',
          _hover: { bg: 'blue.450' },
          _active: { bg: 'blue.400' },
          _focus: { bg: 'blue.500', boder: '2px solid red.400' },
          _disabled: { color: 'white.300', opacity: 1, cursor: 'default' },
        },
        ghost: {
          color: 'blue.300',
          bg: 'white.50',
          _hover: { bg: 'white.100' },
          _active: { bg: 'white.200' },
          _focus: { bg: 'white.50', boxShadow: 'none' },
          _focusVisible: { boxShadow: '0 0 0 2px #2067E3' },
          _disabled: { color: 'white.300', opacity: 1, cursor: 'default' },
        },
        sceneSelect: {
          fontFamily: `Montserrat, 'Noto Sans JP', sans-serif`,
          fontWeight: '600',
          fontSize: '16px',
          lineHeight: '24px',
          letterSpacing: '-0.01em',
          padding: '16px 7px 16px 19px',
          height: 'auto',
          borderRadius: '16px 0 0 16px',
          color: 'white.600',
          bg: 'tranceparent',
          boxShadow: 'none',
          _hover: { bg: 'white.50' },
          _active: { bg: 'white.100' },
          _focus: { bg: 'black.500', boxShadow: 'none' },
          _focusVisible: { boxShadow: '0 0 0 2px #2067E3' },
          _disabled: { color: 'white.300', opacity: 1, cursor: 'default' },
        },
        dropdownMenu: {
          maxWidth: 'calc(100vw - 120px)',
          fontFamily: `Montserrat, 'Noto Sans JP', sans-serif`,
          fontWeight: '600',
          fontSize: '20px',
          lineHeight: '28px',
          letterSpacing: '-0.01em',
          padding: '6px 10px 6px 12px',
          height: 'auto',
          borderRadius: '8px',
          color: 'white.900',
          bg: 'tranceparent',
          boxShadow: 'none',
          outline: 'none !important',
          _active: { boxShadow: '0 0 0 2px #2067E3' },
          _focus: { boxShadow: 'none' },
          _disabled: { color: 'white.300', opacity: 1, cursor: 'default' },
        },
        iconTransparents: {
          color: 'blue.300',
          bg: 'transparent',
          _hover: { bg: { base: 'transparent', sm: 'white.50' } },
          _active: { bg: 'white.100' },
          _focus: { bg: 'transparent', boxShadow: 'none' },
          _focusVisible: { boxShadow: '0 0 0 2px #2067E3' },
          _disabled: { color: 'white.300', opacity: 1, cursor: 'default' },
          transition: `all ${config.FADE_IN_SM.TIME}s cubic-bezier(${config.FADE_IN_SM.EASE_PLAIN})`,
        },
        iconTransparentsWhite: {
          color: 'white.900',
          bg: 'transparent',
          _hover: { bg: { base: 'transparent', sm: 'white.50' } },
          _active: { bg: 'white.100' },
          _focus: { bg: 'transparent', boxShadow: 'none' },
          _focusVisible: { boxShadow: '0 0 0 2px #2067E3' },
          _disabled: { color: 'white.400', opacity: 1, cursor: 'default' },
          transition: `all ${config.FADE_IN_SM.TIME}s cubic-bezier(${config.FADE_IN_SM.EASE_PLAIN})`,
        },
        iconTransparentsBright: {
          color: 'blue.200',
          bg: 'transparent',
          _hover: { bg: { base: 'transparent', sm: 'white.50' } },
          _active: { bg: 'white.100' },
          _focus: { bg: 'transparent', boxShadow: 'none' },
          _focusVisible: { boxShadow: '0 0 0 2px #2067E3' },
          _disabled: { color: 'white.200', opacity: 1, cursor: 'default' },
          transition: `all ${config.FADE_IN_SM.TIME}s cubic-bezier(${config.FADE_IN_SM.EASE_PLAIN})`,
        },
        iconGhost: {
          color: 'blue.300',
          bg: 'white.50',
          _hover: { bg: 'white.100' },
          _active: { bg: 'white.200' },
          _focus: { bg: 'white.50', boxShadow: 'none' },
          _focusVisible: { boxShadow: '0 0 0 2px #2067E3' },
          _disabled: { color: 'white.300', opacity: 1, cursor: 'default' },
        },
        iconShadow: {
          color: 'white.1000',
          bg: 'black.400',
          _hover: { bg: 'black.500' },
          _active: { bg: 'black.600' },
          _focus: { bg: 'black.400', boxShadow: 'none' },
          _focusVisible: { boxShadow: '0 0 0 2px #2067E3' },
          _disabled: { bg: 'rgba(0, 0, 0, 0.12) !important', opacity: 1, cursor: 'default' },
        },
      },
    },
    Link: {
      variants: {
        primary: {
          position: 'relative',
          color: 'blue.200',
          paddingBottom: '2px',
          borderBottom: '1px solid rgba(181, 217, 247, 0)',
          _hover: {
            base: {
              color: 'blue.200',
              textDecoration: 'none',
            },
            sm: { borderBottom: '1px solid rgba(181, 217, 247, 1)' },
          },
          _active: { color: '.200', textDecoration: 'none' },
          _focus: { color: 'blue.200', textDecoration: 'none', boxShadow: 'none' },
          _disabled: { color: 'white.300', textDecoration: 'none', cursor: 'default' },
          transition: `border ${config.FADE_IN_SM.TIME}s cubic-bezier(${config.FADE_IN_SM.EASE_PLAIN})`,
        },
      },
    },
    Progress: {
      baseStyle: {
        filledTrack: {
          bg: 'blue.400',
        },
        track: {
          bg: 'white.100',
        },
      },
    },
    Tabs: {
      variants: {
        secondary: {
          tablist: {
            paddingBottom: '2px',
          },
          tab: {
            fontSize: '14px',
            fonwWeight: '600',
            color: 'white.500',
            padding: '4px 8px 6px',
            position: 'relative',
            _selected: {
              color: 'white.900',
              _after: {
                content: `""`,
                display: 'block',
                w: '16px',
                h: '2px',
                position: 'absolute',
                bottom: '0',
                left: 'calc(50% - 8px)',
                bg: 'blue.500',
              },
            },
            _focus: {
              boxShadow: 'none',
            },
            _focusVisible: {
              boxShadow: '0 0 0 2px #2067E3',
            },
          },
        },
      },
    },
    Text: {
      sizes: {
        '3xl': {
          fontSize: '20px',
          lineHeight: '28px',
          letterSpacing: '-0.01em',
        },
        '2xl': {
          fontSize: '18px',
          lineHeight: '32px',
          letterSpacing: '-0.01em',
        },
        xl: {
          fontSize: '16px',
          lineHeight: '28px',
          letterSpacing: '-0.01em',
        },
        xlLetterSpacingMd: {
          fontSize: '16px',
          lineHeight: '28px',
        },
        lg: {
          fontSize: '16px',
          lineHeight: '24px',
          letterSpacing: '-0.01em',
        },
        md: {
          fontSize: '14px',
          lineHeight: '24px',
          letterSpacing: '-0.01em',
        },
        sm: {
          fontSize: '14px',
          lineHeight: '20px',
          letterSpacing: '-0.01em',
        },
        xs: {
          fontSize: '12px',
          lineHeight: '20px',
        },
      },
    },
    Tooltip: {
      baseStyle: {
        bg: 'black.500',
        color: '#ffffff',
        borderRadius: '4px',
        p: '4px 8px',
        fontSize: '12px',
        lineHeight: '16px',
        boxShadow: 'none',
      },
    },
    Modal: {
      baseStyle: {
        dialog: {
          bg: 'gray.800',
        },
        body: {
          wordBreak: 'break-all',
        },
      },
      variants: {
        navigation: {
          dialogContainer: { zIndex: 'navigation', width: { base: '100%', sm: `${config.NAVIGATION_WIDTH}px` } },
          dialog: {
            width: { base: '100%', sm: `${config.NAVIGATION_WIDTH}px` },
            maxWidth: 'none',
            margin: { base: '24px 0 0', sm: '0' },
            boxShadow: 'none',
            userSelect: 'none',
          },
          header: {
            padding: { base: '0' },
          },
          body: {
            padding: { base: '0' },
          },
        },
        tagModal: {
          dialogContainer: {
            pointerEvents: 'none',
            display: { base: 'grid', sm: 'block' },
            alignItems: { base: 'end', sm: 'normal' },
            zIndex: { base: 'tagModalBase', sm: 'tagModalSm' },
          },
          dialog: {
            maxWidth: 'none',
            margin: { base: '24px 0 1px', sm: '0 0 1px' },
            borderRadius: { base: '16px 16px 0 0', sm: '16px' },
            pointerEvents: 'auto',
            boxShadow: { base: 'none', sm: '0px 4px 12px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.02)' },
            zIndex: { base: 'tagModalBase', sm: 'tagModalSm' },
            userSelect: 'none',
            position: { base: 'static', sm: 'absolute' },
            top: { base: 'auto', sm: '50%' },
            left: { base: 'auto', sm: '50%' },
          },
          body: {
            width: { base: '100vw', sm: 'auto' },
          },
        },
        tagModalFullscreen: {
          dialogContainer: {
            pointerEvents: 'none',
            display: { base: 'grid', sm: 'block' },
            alignItems: { base: 'end', sm: 'normal' },
            zIndex: 'tagModalFullBase',
          },
          dialog: {
            bg: 'black.600',
            maxWidth: 'none',
            h: '100svh',
            margin: '0',
            borderRadius: '0',
            pointerEvents: 'auto',
            zIndex: 'tagModalFullBase',
            userSelect: 'none',
            position: 'absolute',
            top: '0',
            left: '0',
          },
          body: {
            width: { base: '100vw', sm: 'auto' },
          },
        },
        tagModalScroll: {
          dialogContainer: {
            pointerEvents: 'auto',
            display: { base: 'grid', sm: 'block' },
            alignItems: { base: 'end', sm: 'normal' },
            zIndex: { base: 'tagModalBase', sm: 'tagModalSm' },
          },
          dialog: {
            maxWidth: 'none',
            margin: { base: '24px 0 1px', sm: '0 0 1px' },
            borderRadius: { base: '16px 16px 0 0', sm: '16px' },
            pointerEvents: 'auto',
            boxShadow: { base: 'none', sm: '0px 4px 12px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.02)' },
            zIndex: { base: 'tagModalBase', sm: 'tagModalSm' },
            userSelect: 'none',
            position: { base: 'static', sm: 'absolute' },
            top: { base: 'auto', sm: '50%' },
            left: { base: 'auto', sm: '50%' },
          },
          body: {
            width: { base: '100vw', sm: 'auto' },
          },
        },
        infoModal: {
          dialogContainer: {},
          dialog: {
            width: '100%',
            height: { base: '100%', sm: 'auto' },
            maxWidth: { base: '100%', sm: '800px' },
            maxHeight: { base: '100%', sm: 'none' },
            marginTop: { base: '0', sm: '56px' },
            marginBottom: { base: '0', sm: '56px' },
            borderRadius: { base: '0', sm: '16px' },
            transform: 'scale(1,1) !important',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.02)',
          },
          header: {
            flex: 'none',
            height: '56px',
            padding: 0,
          },
          body: {
            padding: { base: '9px 16px 64px', sm: '56px 72px 84px' },
          },
        },
        mapModal: {
          overlay: {
            zIndex: 'mapModal',
            display: { base: 'none', sm: 'block' },
            pointerEvents: 'auto',
            cursor: 'pointer',
            background: 'black.500',
          },
          dialogContainer: {
            display: { base: 'grid', sm: 'block' },
            alignItems: { base: 'end', sm: 'normal' },
            zIndex: 'mapModal',
          },
          dialog: {
            w: { base: '100vw', sm: 'calc(100vw - 120px)' },
            minW: { sm: '680px' },
            h: { base: '100svh', sm: 'auto' },
            margin: { base: '0', sm: '0 0 1px' },
            bg: { base: 'black.500', sm: 'gray.800' },
            borderRadius: { base: '0', sm: '16px' },
            boxShadow: { base: 'none', sm: '0px 4px 12px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.02)' },
            zIndex: 'mapModal',
            userSelect: 'none',
            position: { base: 'static', sm: 'absolute' },
            top: { base: 'auto', sm: '50%' },
            left: { base: 'auto', sm: '50%' },
            transform: { sm: `translate(-50%,max(-50%, calc(-50svh + 56px))) scale(1,1) !important` },
          },
          body: {
            width: { base: '100vw', sm: 'auto' },
          },
        },
      },
    },
  },
})
export default theme
