import { Image as CkakraImage, ImageProps } from '@chakra-ui/react'
import axios from 'axios'
import { forwardRef, memo, useEffect, useRef, useState } from 'react'
import { useMessage } from '../../../hooks/useMessage'

/**
 * リクエストヘッダーにCookieを追加
 */
export type SignedImageType = {
  src: string
  onLoaded?: () => void
} & ImageProps
const ForwardRefSignedImage = forwardRef<HTMLImageElement, SignedImageType>(({ src, onLoaded, ...props }, ref) => {
  const [data, setData] = useState(null)
  const isMounted = useRef(true)
  const { showMessage } = useMessage()

  useEffect(() => {
    if (!src) {
      return
    }
    isMounted.current = true
    axios
      .get(src, {
        withCredentials: true,
        responseType: 'blob',
      })
      .then((res) => {
        if (!isMounted.current) {
          return
        }
        setData([URL.createObjectURL(res.data)])
      })
      .catch((error) => {
        if (!isMounted.current) {
          return
        }
        console.log('画像が取得できません。ページを再読み込みしてください。', src, error)
        showMessage({
          text: '画像が取得できません。ページを再読み込みしてください。',
          status: 'error',
        })
      })
    return () => {
      isMounted.current = false
    }
  }, [src])

  return <>{data !== null && <CkakraImage ref={ref} src={data} {...props} onLoad={onLoaded} />}</>
})

export const SignedImage = memo(ForwardRefSignedImage)
